import '../css/Generate.css';
import 'rc-slider/assets/index.css';
import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import {useLocation} from 'react-router-dom';
import { PubNubProvider } from 'pubnub-react';
import PubNub from 'pubnub';
import { v4 as uuidv4 } from 'uuid';
import { Triangle } from  'react-loader-spinner'

const pubnub = new PubNub({
  subscribeKey: 'sub-c-54699b10-4413-4b5f-b372-53b8dd4b2d38',
  uuid: uuidv4()
});

function Upload() {
  const location = useLocation();
  let navigate = useNavigate();

  const [channels] = useState([location.state.channel]);

  useEffect(() => {

    const handleMessage = event => {
      const message = event.message;
      console.log(message?.gif);
  
      navigate("/download", { replace: false, state: { gif: message?.gif } });
    };

    pubnub.addListener({ message: handleMessage });
    pubnub.subscribe({ channels });
  }, [/*pubnub, */channels, navigate]);

  return (
    <PubNubProvider client={pubnub}>
      <div className="generating">
        <h1>Creating GIF...</h1>
        <Triangle
          height="80"
          width="80"
          color="#6BD33E"
          ariaLabel="triangle-loading"
          wrapperStyle={{}}
          wrapperClassName=""
          visible={true}
        />
      </div>
    </PubNubProvider>
  )
}

export default Upload;