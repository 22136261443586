const baseUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:9999' : 'https://giffy.qrafting.com';

function uploadFileToS3(urlData, file) {
  return new Promise((resolve, reject) => {
      const formData = new FormData();
      Object.keys(urlData.fields).forEach(key => {
          formData.append(key, urlData.fields[key]);
      });

      // Actual file has to be appended last.
      formData.append("file", file);

      const xhr = new XMLHttpRequest();
      xhr.open("POST", urlData.url, true);
      xhr.send(formData);
      xhr.onload = function() {
          this.status === 204 ? resolve() : reject(this.responseText);
      };
  });
};

const accept = [
  '.jpeg',
  '.jpg',
  '.png'
];

async function doFileUpload(files) {
  if(files.length <= 0) return;
  const file = files[0];

  if(!file || !file.name || file.name.indexOf('.') < 0) {
      throw new Error('Invalid file');
  }

  const ext = file.name.substr(file.name.lastIndexOf('.')).toLowerCase();
  if(accept.indexOf(ext) < 0) {
      throw new Error('Invalid file type');
  }

  let urlData;
  const res = await fetch(`${baseUrl}/.netlify/functions/get-upload-url`, {
      method: 'POST',
      body: JSON.stringify({filename: file.name})
  });
  urlData = await res.json();

  const reader = new FileReader();
  reader.addEventListener('error', async (error) => {
    throw error;
  });
  
  return new Promise((resolve) => {
    reader.addEventListener('load', async (event) => {
      let fileType = file.type;
      if(!fileType) {
        throw new Error('Missing file extension');
      }
      const blob = new Blob([new Uint8Array(event.target.result)], {type: fileType });

      await uploadFileToS3(urlData, blob);
      
      resolve({bucket: urlData.fields.bucket, key: urlData.fields.key});
    });

    reader.readAsArrayBuffer(file);
  })
  
}

async function generateGif(cfg) {
  return await fetch(`${baseUrl}/.netlify/functions/generate-gif`, {
      method: 'POST',
      body: JSON.stringify(cfg)
  });
}

const api = {
  doFileUpload,
  generateGif
}

export default api;