import '../css/Configure.css';
import slow from '../static/slow.gif';
import medium from '../static/medium.gif';
import fast from '../static/fast.gif';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import api from '../services/api';
import { ThreeCircles } from  'react-loader-spinner'
import { AwesomeButton } from "react-awesome-button";
import "react-awesome-button/dist/styles.css";
import * as Sentry from "@sentry/react";

const speedMarks = {
  0: {
    style: {
      color: 'red'
    },
    label: 'Slow'
  },
  50: {
    style: {
      color: 'yellow'
    },
    label: 'Medium'
  },
  100: {
    style: {
      color: 'green'
    },
    label: 'Fast'
  },
};

const sizeMarks = {
  0: {
    style: {
      color: 'red'
    },
    label: 'Small'
  },
  50: {
    style: {
      color: 'yellow'
    },
    label: 'Medium'
  },
  100: {
    style: {
      color: 'green'
    },
    label: 'Large'
  },
};

function Configure() {
  const [speed, setSpeed] = useState(50);
  const [size, setSize] = useState(50);
  const [uploading, setUploading] = useState(false);

  const navigate = useNavigate();

  const imgSource = {
    0: slow,
    50: medium,
    100: fast
  }[speed];

  const imgSize = {
    0: 'small',
    50: 'medium',
    100: 'large'
  }[size];

  function speedChange(value) {
    setSpeed(value);
  }
  function sizeChange(value) {
    setSize(value);
  }

  function uploadPressed() {
    hiddenFileInput.current.click();
  }

  const hiddenFileInput = React.useRef(null);
  async function fileChanged(e) {
    let uploadInfo;
    try {
      setUploading(true);
      uploadInfo = await api.doFileUpload(e.target.files);
    } catch(e) {
      Sentry.captureException(e);
      setUploading(false);
      console.log('failed to upload file', e);
      return;
    }

    const channel = uuidv4();

    try {
      await api.generateGif({
        file: {
          bucket: uploadInfo.bucket,
          key: uploadInfo.key,
        },
        channel,
        options: {
          size,
          speed
        }
      })
    } catch(e) {
      Sentry.captureException(e);
      console.log('failed to generate gif', e);
    }

    navigate("/generate", { replace: false, state: { channel } });
  }

  return (
    <div>
      {!uploading && 
      <div>
        <h1>GIF Setup</h1>
        <div className="img-container">
          <img src={imgSource} className={`example-gif-${imgSize}`} alt="example-gif" />
        </div>
        <Slider className="speed-slider" marks={speedMarks} step={null} defaultValue={speed} onChange={speedChange} 
          handleStyle={{
            height: 22,
            width: 22,
            marginTop: -9,
          }}
        />
        <Slider className="size-slider" marks={sizeMarks} step={null} defaultValue={size} onChange={sizeChange} 
          handleStyle={{
            height: 22,
            width: 22,
            marginTop: -9,
          }}
        />
        <div className="file-button">
          <input style={{display: 'none'}} type="file" onChange={fileChanged} ref={hiddenFileInput}/>
          <AwesomeButton
            style={{
              '--button-primary-color':"#5bb535",
              '--button-primary-color-hover': "#6BD33E",
              '--button-primary-color-active': "#6BD33E",
            }}
            ripple
            size={"large"}
            onPress={uploadPressed}
          >Upload</AwesomeButton>
        </div>
      </div>
      }
      {uploading &&
      <div className="uploading">
        <h1>Uploading...</h1>
        <ThreeCircles
          height="100"
          width="100"
          color="#6BD33E"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
          ariaLabel="three-circles-rotating"
          outerCircleColor=""
          innerCircleColor=""
          middleCircleColor=""
        />
      </div>
      }
    </div>
  )
}

export default Configure;