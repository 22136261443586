import './App.css';
import React from 'react';
import Configure from './components/Configure';
import Generate from './components/Generate';
import Download from './components/Download';
import { Routes, Route } from "react-router-dom";

function App() {
  return (
      <div className="App">
        <header className="App-header">
          <Routes>
            <Route path="/" element={<Configure/>} />
            <Route path="/generate" element={<Generate/>} />
            <Route path="/download" element={<Download/>} />
          </Routes>
        </header>
      </div>
  );
}

export default App;
