import '../css/Download.css';
import {useLocation} from 'react-router-dom';
import { AwesomeButton } from "react-awesome-button";
import "react-awesome-button/dist/styles.css";
import { useNavigate } from "react-router-dom";

function Download() {
  const location = useLocation();
  const navigate = useNavigate();

  const handleRestart = () => {
    navigate("/", { replace: false });
  }

  return (
    <div>
      <div className="img-container">
        <img className="generated-img" src={location.state.gif} alt="generated-gif"/>
      </div>
      <div className="download-container">
          <AwesomeButton
            type="primary"
            style={{
              '--button-primary-color':"#5bb535",
              '--button-primary-color-hover': "#6BD33E",
              '--button-primary-color-active': "#6BD33E",
            }}
            ripple
            size={"large"}
            href={location.state.gif}
          >Download</AwesomeButton>
      </div>
      <div className="restart-container">
          <AwesomeButton
            type="primary"
            style={{
              '--button-primary-color':"#33a8b0",
              '--button-primary-color-hover': "#3cccd6",
              '--button-primary-color-active': "#3cccd6",
            }}
            ripple
            size={"large"}
            href={location.state.gif}
            onPress={handleRestart}
          >Make Another GIF</AwesomeButton>
        
      </div>
    </div>
  )
}

export default Download;